// Ionic Styles
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Variables
@import "/src/theme/variables.scss";
// Custom Materials SCSS
@import '/src/theme/material.scss';
@import './src/theme/animations.scss';

// Swiper css
@import "~swiper/css";
@import "~swiper/css/pagination";
@import "~swiper/css/navigation";

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

// Main Styling
* {
    box-sizing: border-box;
    font-family: urbane, sans-serif;
}
html { 
    scroll-behavior : smooth;
    overflow: hidden;
}
body {
    margin: 0;
    color: var(--text-color);
    background-color: var(--background-color);
    height: 100%;
    z-index: 1;
}
ion-content {
    --background: var(--background-color) !important;
    --color: var(--text-color) !important;
}
ion-searchbar {
    color: var(--text-color) !important;
}

.toggle-filter {
    *ion-list {
        background: var(--background-color) !important;
        *ion-item {
            color: var(--text-color) !important;
            
        }
    }
}
.item .item-content {
    background: var(--background-color) !important;
}
h1, h2, h3, h4, h5 {
    font-family: eurostile-extended, sans-serif;
}
small {
    color: rgb(var(--ion-color-dark-rgb), .75)
}
.red-font {
    color: var(--color-red);
}
.courier {
    font-family: courier-prime, monospace !important;
    font-weight: 400 !important;
    font-style: normal !important;
}
.pointer {
    cursor: pointer;
}
.textCursor {
    cursor: text;
}
.text-color-2 {
    color: var(--text-color-2);
}
.text-color-3 {
    color: var(--text-color-3) !important;
}
.gray, .middle-gray { color: var(--text-color-3)
}
.green { color: var(--ion-color-success) !important }
.yellow { color: var(--ion-color-warning) }
.red { color: var(--ion-color-danger) !important }

.ultrathin  { font-weight: 100; }
.thin       { font-weight: 200; }
.light      { font-weight: 300; }
.regular    { font-weight: 400; }
.medium     { font-weight: 500; }
.semibold   { font-weight: 600; }
.bold       { font-weight: 700; }
.extrabold  { font-weight: 800; }
.heavy      { font-weight: 900; }

.my-small { font-size:  75%; }

// Buttons
.red-btn-outline {
    color: var(--color-pink);
    border: 2px solid var(--color-pink);
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 10px;
    transition: .2s;
    
    &:hover {
        color: white ;
        background-color:  var(--color-pink) ;
        border: 2px solid var(--color-pink) ;
        // background-color: white;
        // color: black;
        // border-color: black;
    }
}

.red-btn-solid {
    background-color:  var(--color-pink);
    color: white !important;
    border: 2px solid var(--color-pink);
    padding: 10px 30px !important;
    font-weight: 600;
    transition: .2s;
    border-radius: 10px;

    &:hover {
        background-color:  var(--color-pink) !important;
        border: 2px solid var(--color-pink) !important;

        // background-color: black;
        // border-color: black;
    }
}
.btn-solid {
    padding: 15px 25px;
    background: var(--color-pink);
    color: white;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    transition: .3s;

    &:hover {
        // opacity: .85;
        background-color: var(--text-color-accent);
        border-color: var(--text-color-accent);
        color: white;
        box-shadow: 0 0 20px var(--box-shadow);
    }

    ion-icon {
        height: 15px;
        width: 15px;
        color: white;
        margin-right: 5px;
    }
}

.btn-danger {
    background-color: rgb(0,0,0,0);
    color: var(--color-red) !important;
    border: 2px solid var(--color-red) !important;
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 10px;
    transition: .2s;
    
    &:hover {
        color: white !important;
        background-color:  var(--color-red) ;
        border: 2px solid var(--color-red) ;
        // background-color: white;
        // color: black;
        // border-color: black;
    }
}

.btn-white {
    background-color: white;
    color: var(--color-pink) !important;
    border: 2px solid white !important;

    &:hover {
        color: white !important;
        background-color:  rgb(0,0,0,0) ;
        border: 2px solid white ;
        // background-color: white;
        // color: black;
        // border-color: black;
    }
}
.btn-white-outline {
    background-color:  rgb(0,0,0,0) ;
    color: white !important;
    border: 2px solid white !important;
    
    &:hover {
        background-color: white;
        color: var(--color-pink) !important;
        border: 2px solid white ;
        // background-color: white;
        // color: black;
        // border-color: black;
    }
}

.btn { 
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 10px;
    transition: .2s;
    outline: 0 !important;

    &* {
        outline: 0 !important;
    }
}

.btn-main {
    background-image: linear-gradient(
        to right, 
            rgb(254, 88, 88) 0%,
            rgba(255,2,85,1) 20%, 
            rgba(255,2,85,1) 30%, 
            rgba(255,1,237,1) 80%, 
            rgba(255,1,237,1) 100%);

    background-size: 200% auto;

    // background-color: var(--color-pink);
    color: white;
    // width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    // padding: .7rem 1rem;
    transition: .5s !important;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;

    &:hover {
        background-position: right center;
        color: white !important;


        span {
            transform: scale(1.01);
        }
        // filter: drop-shadow(0 2px 5px var(--box-shadow));
    }

    &.disabled {
        opacity: .5 !important;
        // filter: grayscale(.5);
        pointer-events: none !important;
    }

    ion-icon {
        color: white !important;
    }

    :first-child {
        margin-right: 10px;

    }

    &.outline {
        color: var(--color-pink);
        border: 1px solid var(--color-pink);
        background-image: none;
        transition: .1s !important;

        ion-icon {
            color: var(--color-pink) !important;
        } 

        &:hover {
            background-image: linear-gradient(
                to right, 
                    rgb(254, 88, 88) 0%,
                    rgba(255,2,85,1) 20%, 
                    rgba(255,2,85,1) 30%, 
                    rgba(255,1,237,1) 80%, 
                    rgba(255,1,237,1) 100%);

            color: white;

            ion-icon {
                color: white !important;
            } 
        }
    }
}


.btn-main-outline {
    border: 2px solid var(--accent-color);
    color: var(--text-color);
    width: 100%;
    border-radius: 10px;
    padding: .7rem 1rem;
    transition: .2s;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    text-transform: uppercase;


    &:hover {
        background-color: var(--background-color);
        color: var(--text-color);
        transform: scale(1.02) translateY(-2px);
        filter: drop-shadow(0 2px 5px rgba(97, 120, 255, .4));
    }
}

button:focus { outline: 0 !important; }


.card {
    .card-header {
        padding: 0 !important;
        .header {
            font-family: eurostile-extended, sans-serif !important;
            font-size: 25px !important;
        }
    }
    .card-body {
        text-align: center;
        color: #949494 !important;
    }
    .card-footer {
        padding-top: 1rem !important;
    }
}

// Spacing
.flex-center { 
    display: flex; 
    align-items: center;
    justify-content: center;
}
.flex-between { 
    display: flex; 
    align-items: center;
    justify-content: space-between;
}
.flex-right, .flex-end { 
    display: flex; 
    align-items: center;
    justify-content: flex-end;
}
.flex-left, .flex-start { 
    display: flex; 
    align-items: center;
    justify-content: flex-start;
}

// Landing Page banner - swiper styles
.banner-right {
    .swiper-pagination { 
        bottom: -3px !important;
    }
    
    .swiper-pagination-clickable .swiper-pagination-bullet {
        width: 25px !important;
        border-radius: 4px !important;
        height: 5px !important;
        background: #ffffff;
        opacity: unset;
    }

    .swiper-pagination-bullet-active {
        background: var(--color-red) !important;
    }
}

.swiper-slide {
    height: auto !important;
}

.collection-swiper {
    .swiper-pagination { 
        bottom: -5px !important;
    }
}

// Modal CSS - Login / Register
.tab2 {
    .mat-tab-label {
        width: 50% !important;
        padding: 0 !important;
    }
}

.buy-nft-modal {
    .alert-title {
        color: var(--text-color);
    }

    .alert-message {
        color: var(--text-color);
    }

    .alert-wrapper {
        border-radius: 8px;
        background-color: var(--background-color) !important;
        color: var(--text-color);
    }

    .alert-button-group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            flex: 1 1 auto;
            border-radius: 5px;
            color: white;
            font-size: 16px;
        }

        button:nth-child(1) {
            background-color : rgb(114, 218, 114) !important; 
        }

        button:nth-child(2) {
            background-color : var(--color-red) !important; 
        }

        .alert-button-inner {
            justify-content: center !important;
        }
    }
    // .alert-button {
    //     color: white;
    //     background-color: var(--color-red);
    //     border-radius: 8px;
    // }
}
// ANIMATIONS
.duration1 { 
    animation-duration: .5s !important; 
}

// Extras
.border { border: 3px solid var(--accent-color) !important }
.flex-center { 
    display: flex; 
    align-items: center;
    justify-content: center;
}
.flex-between { 
    display: flex; 
    align-items: center;
    justify-content: space-between;
}
.flex-start, .flex-left { 
    display: flex; 
    align-items: center;
    justify-content: flex-start;
}
.block { display: block; }
.glow-img {
    filter: drop-shadow(0 1px 8px var(--accent-color));
}
.glow {
    // box-shadow: 0 1px 8px rgb(97, 120, 255, .35)

    filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
}
.light-glow {
    filter: drop-shadow(0 1px 8px rgba(97, 120, 255, .75));
}

.pointer { cursor: pointer; }
.uppercase { text-transform: uppercase; }

.br-15 { border-radius: 15px; }
.br-10 { border-radius: 10px; }


// Swapping background images based on dark mode 
body.dark {
    // Home page sections
    .section-1 {
        background-image: url("./assets/Main_background_dark.png") !important;
    }

    .section-3 {
        background-image: url("./assets/Features_background_dark.png") !important;
    }

    .section-5 {
        background-image: url("./assets/Messaging_background_dark.png") !important;
        // height: 33vw !important;
    }

    .section-6 {
        background-image: url("./assets/MandoxGlobal_background_dark.png") !important;
        // height: 31vw !important;
    }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input, textarea {
    background-color: var(--background-color);
    border: 1px solid var(--color-grey);
    padding-inline-start: 12px;
    background-color: var(--background-color);
    color: var(--text-color);
    border-radius: 4px;
}
textarea {
    padding: 1rem;
}

select {
    background-color: var(--background-color) !important;
    color: var(--text-color) !important;
    border: 1px solid var(--color-grey) !important;
    border-radius: 4px !important; 
}

// Material Styling

.mat-tab-label .mat-tab-label-content {
    color: var(--ion-color-dark)
}
.mat-tab-nav-bar, .mat-tab-header {
    border-bottom: 1px solid rgba(var(--ion-color-dark-rgb), .2);
}
.mat-form-field-ripple {
    background-color: var(--color-pink)  !important;
}
.mat-form-field-label {
    color: rgba(var(--ion-color-dark-rgb), .5) !important;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--color-pink) !important;
}

.mat-focused .mat-form-field-required-marker {
    color: var(--color-pink) !important;
}
.mat-form-field-underline {
    background-color: rgba(var(--ion-color-dark-rgb), .2) !important;
}
.mat-input-element {
    // color: blue !important;
}

.address {
    background-color: var(--icon-btn-bg);
    color: var(--ion-color-dark);
    box-shadow: 0 2px 5px var(--box-shadow);
    border-radius: 5px;
    padding: .2rem .4rem;
    font-size: 75%;
    width: max-content;
    cursor: pointer;
    transition: .2s;

    &.icon {
        margin-left: 5px;
        padding: 7px 8px;
    } 

    mat-icon {
        font-size: 20px;
        height: 20px;
        width: 20px;
    }

    &:hover {
        transform: translateY(-2px);
        color: var(--color-pink);
        box-shadow: 0 5px 5px var(--box-shadow) !important;
    }
    span {
        font-family: courier-prime, monospace !important;

    }
}

// .my-input {
    // input[type=text], input[type=number] {
    //     padding: 10px;
    //     width: 100%;
    //     border-radius: 10px;
    //     margin: .5rem 0 0 0;
    //     background: var(--accent-color-two);
    //     outline: 1px solid var(--light-border);
    //     border: none;
    //     color: var(--base);

    //     &:focus {
    //         outline: 1px solid var(--color-pink) !important;
    //     }
    // }

    // textarea {
    //     width:100%;
    //     resize: vertical;
    //     padding:10px;
    //     border-radius: 10px;
    //     outline: 1px solid var(--light-border);
    //     background: var(--accent-color-two);
    //     height:90px;
    //     border: none;
    //     margin: 1rem 0;
    //     color: var(--base);

    //     &:focus {
    //         outline: 1px solid var(--color-pink) !important;
    //     }
    // }

    *::placeholder {
        // font-weight: bold;
        // opacity: .5;
        color: var(--text-color-3);
    }
// }

mat-form-field {
    ion-icon {
        font-size: 25px;
        color: rgba(var(--ion-color-dark-rgb), .5) 
    }

    &.mat-form-field-invalid {
        ion-icon {
            font-size: 25px;
            color: var(--color-red) !important;
        }

        .mat-form-field-ripple {
            background-color: var(--color-red)  !important;
        }
    }
}

.mat-error {
    text-align: right;
    color: var(--color-red) !important;
}

ion-toast {
    z-index: 1000 !important;
}

ion-skeleton-text {
    --background-rgb: var(--color-pink-rgb);
    margin: 0 !important;
    border-radius: 10px;
}

.my-toast {
    z-index: 1000;
}

// disable default ion icon hover tooltip
ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

.opacity-hover {
    opacity: .5;
    transition: .2s;

    &:hover {
        opacity: 1;
    }
}

.light-box {
    width:100%;
    resize: vertical;
    padding:10px;
    border-radius: 10px;
    outline: 1px solid var(--light-border);
    background: var(--accent-color-two);
    // height:100%;
    border: none;
    margin: 1rem 0;
    color: var(--base);

    &:focus {
        outline: 1px solid var(--color-pink) !important;
    }
}

.facebook:hover, .facebook-active   ion-icon { color: $facebook !important }
.twitter:hover, .twitter-active     ion-icon { color: $twitter !important }
.youtube:hover, .youtube-active     ion-icon { color: $youtube !important }
.instagram:hover, .instagram-active ion-icon { color: $instagram !important }
.linkedin:hover, .linkedin-active   ion-icon { color: $linkedin !important }
.medium:hover, .medium-active       ion-icon { color: $medium !important }
.discord:hover, .discord-active     ion-icon { color: $discord !important }
.twitch:hover, .twitch-active       ion-icon { color: $twitch !important }
.link:hover, .link-active           ion-icon { color: var(--color-pink) !important }

.pink-hover {
    transition: .2s !important;
    &:hover { color: var(--color-pink) !important }
}


ion-segment-button {
    // min-width: 0 !important;

    ion-label {
        color: #949494;;
    }
}
ion-segment-button.segment-button-checked {
    ion-label {
        color: var(--ion-color-primary);
    }
}
ion-label {
    text-transform: initial !important;
    white-space: initial !important;
}
.segment-border-bottom {
    border-bottom: 1px solid var(--light-border);
    ion-segment { margin-bottom: -1.5px; }
    background-color: var(--background);
}
.tab2 {
    ion-segment-button {
        width: 50% !important;
        padding: 0 !important;
    }
}

.no-shrink {
    ion-chip {
        flex-shrink: 0 !important
    }
}
.responsive-flex {
    align-items: center;

    @media screen and (min-width: 575px){
        display: flex;
    }

    @media screen and (max-width: 574px){
        display: block;

        ion-chip:first-child {
            margin-left: 0 !important
        }
        ion-searchbar {
            margin-top: .9rem !important;
        }
    }
}
.separator { 
    border-bottom: 1px solid rgb(127,127,127, .2);
    margin: 1rem 0;
}
.profile-chip {
    margin-right: 0px !important;
    opacity: .7;
    border-radius: 10px !important;
    height: 36px !important;

    &:hover {
        opacity: 1 !important;
    }
}

.searchbar-input {
    background-color: var(--chip) !important;
}
ion-chip {
    ion-label {
        *:nth-child(2){
            margin-left: .5rem;
        }
    }
}

.border-bottom {
    border-bottom: 1px solid var(--light-border) !important;
}

*:disabled {
    opacity: .5 !important;
}
.pointer {
    cursor: pointer !important;
}
.light-border{
    color: var(--light-border)
}
.text-right {
    text-align: right !important;
}
.primary {
    color: var(--color-pink)
}
.airwireName {
    color: var(--text-color-3)
}

ion-searchbar {
    margin: 0 !important
}

ion-toast::part(header) {
    font-weight: 800 !important;
    font-size: 16px;
}
ion-toast::part(container) {
    font-weight: 500 !important;
    font-size: 15px;
}

.alert-button-group {
    padding: 1rem .5rem 1rem 1rem !important;
}