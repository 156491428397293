.trans { transition: .3s; }

// Animations
.floating {
    transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}
// Mobile Styling
@media(max-width:991px){
    @keyframes float {
        0% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
        50% {
            filter: drop-shadow(0 10px 15px rgba(97, 120, 255, .5));
            transform: translatey(-3px) rotate(0deg);
        }
        100% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
    }
}
// Desktop styling
@media(min-width:992px){
    @keyframes float {
        0% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
        50% {
            filter: drop-shadow(0 10px 15px rgba(97, 120, 255, .5));
            transform: translatey(-8px) rotate(0deg);
        }
        100% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
    }
}
.floating-small {
    transform: translatey(0px);
	animation: float-small 4s ease-in-out infinite;
}
@keyframes float-small {
    0% {
        filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
        transform: translatey(1.5px) rotate(-2deg);
    }
    50% {
        filter: drop-shadow(0 4px 15px rgba(97, 120, 255, .5));
        transform: translatey(-1.5px) rotate(2deg);
    }
    100% {
        filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
        transform: translatey(1.5px) rotate(-2deg);
    }
}

@-webkit-keyframes translate-left-in {
    0%   { -webkit-transform:translateX(-20px); }
    100% { -webkit-transform:translateX(0); }
}
@keyframes translate-left-in {
    0%   { transform:translateX(-20px); }
    100% { transform:translateX(0); }
}

@-webkit-keyframes translate-left-out {
    0%   { -webkit-transform:translateX(0%); }
    100% { -webkit-transform:translateX(20px); }
}
@keyframes translate-left-out {
    0%   { transform:translateX(0%); }
    100% { transform:translateX(20px); }
}


// ANIMATIONS
.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInUp {
    0% { 
        transform: translate3d(0,35px,0) 
    }
    50% {
        transform: translate3d(0,-2px,0) 
    }
    100% { 
        transform: translate3d(0,0,0); opacity: 1 
    }
}
@-webkit-keyframes fadeInUp {
    0% { 
        transform: translate3d(0,35px,0) 
    }
    50% {
        transform: translate3d(0,-2px,0) 
    }
    100% { 
        transform: translate3d(0,0,0); opacity: 1 
    }
}

.fadeInRight {
    opacity: 0;
    animation-name: fadeInRight;
    -webkit-animation-name: fadeInRight;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(15px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 
.fadeInLeft {
    opacity: 0;
    animation-name: fadeInLeft;
    -webkit-animation-name: fadeInLeft;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-15px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 
.zoomIn {
    opacity: 0;
    animation-name: zoomIn;
    -webkit-animation-name: zoomIn;

    animation-duration: .2s;
    animation-fill-mode: both;
    -webkit-animation-duration: .2s;
    -webkit-animation-fill-mode: both;
}
@keyframes zoomIn {
    0% {
       opacity: 0;
       transform: scale(0.95);
    }
    50% {
       opacity: 1;
       transform: scale(1.02);
    }
    100% {
       opacity: 1;
       transform: scale(1);
    }
 } 


.slideInUp {
    animation-name: slideInUp;
    -webkit-animation-name: slideInUp;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes slideInUp {
    // 0% {
    //     transform: translate3d(0,40px,0)
    // }
    // 60% {
    //     transform: translate3d(0,-5px,0)
    // }
    // 100% {
    //     transform: translate3d(0,0,0);
    // }
    from { transform: translate3d(0,40px,0) }
    to { transform: translate3d(0,0,0); }
}
@-webkit-keyframes slideInUp {
    // 0% {
    //     transform: translate3d(0,40px,0)
    // }
    // 60% {
    //     transform: translate3d(0,-10px,0)
    // }
    // 100% {
    //     transform: translate3d(0,0,0);
    // }
    from { transform: translate3d(0,40px,0) }
    to { transform: translate3d(0,0,0); }
}

.slideInDown {
    animation-name: slideInDown;
    -webkit-animation-name: slideInDown;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes slideInDown {
    from { transform: translate3d(0,-40px,0) }
    to { transform: translate3d(0,0,0); }
}
@-webkit-keyframes slideInDown {
    from { transform: translate3d(0,-40px,0) }
    to { transform: translate3d(0,0,0); }
}

.slideInRight {
    animation-name: slideInRight;
    -webkit-animation-name: slideInRight;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes slideInRight {
    from { transform: translate3d(-100px,0,0) }
    to { transform: translate3d(0,0,0); }
}
@-webkit-keyframes slideInRight {
    from { transform: translate3d(-100px,0,0) }
    to { transform: translate3d(0,0,0); }
}

.fadeInDown {
    opacity: 0;
    animation-name: fadeInDown;
    -webkit-animation-name: fadeInDown;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInDown {
    from { transform: translate3d(0,-40px,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}
@-webkit-keyframes fadeInDown {
    from { transform: translate3d(0,-40px,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}

.downUp {
    position: relative;
    animation: downUp 2s ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation: downUp 2s ease-in-out;
    -webkit-animation-iteration-count: infinite;
}
@keyframes downUp {
    0%   { top:0; }
    15%  { top:15px; } 
    30%  { top:0%; } 
}
@-webkit-keyframes downUp {
    0%   { top:0; }
    15%  { top:15px; } 
    30%  { top:0%; } 
}
.upDown {
    position: relative;
    animation: upDown 2s ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation: upDown 2s ease-in-out;
    -webkit-animation-iteration-count: infinite;
}
@keyframes upDown {
    0%   { bottom:0; }
    15%  { bottom:15px; } 
    30%  { bottom:0%; } 
}
@-webkit-keyframes upDown {
    0%   { bottom:0; }
    15%  { bottom:15px; } 
    30%  { bottom:0%; } 
}

.delay1 { animation-delay: .05s; }
.delay2 { animation-delay: .1s; }
.delay3 { animation-delay: .15s; }
.delay4 { animation-delay: .2s; }
.delay5 { animation-delay: .25s; }
.delay6 { animation-delay: .3s; }
.delay7 { animation-delay: .35s; }
.delay8 { animation-delay: .4s; }
.delay9 { animation-delay: .45s; }
.delay10 { animation-delay: .5s; }
.delay11 { animation-delay: .6s; }
.delay12 { animation-delay: .7s; }
.delay13 { animation-delay: .8s; }
.delay14 { animation-delay: .9s; }
.delay15 { animation-delay: 1s; }
.delay16 { animation-delay: 1.1s; }
.delay20 { animation-delay: 2s; }

.duration1 { animation-duration: .5s !important; }
.duration2 { animation-duration: .75s !important; }
.duration3 { animation-duration: 1s !important; }

.delay-child {
    &:nth-child(1) { animation-delay: .05s }
    &:nth-child(2) { animation-delay: .1s }
    &:nth-child(3) { animation-delay: .15s }
    &:nth-child(4) { animation-delay: .2s }
    &:nth-child(5) { animation-delay: .25s }
    &:nth-child(6) { animation-delay: .3s }
    &:nth-child(7) { animation-delay: .35s }
    &:nth-child(8) { animation-delay: .4s }
    &:nth-child(9) { animation-delay: .45s }
    &:nth-child(10) { animation-delay: .5s }
    &:nth-child(11) { animation-delay: .55s }
    &:nth-child(12) { animation-delay: .6s }
    &:nth-child(13) { animation-delay: .65s }
    &:nth-child(14) { animation-delay: .7s }
    &:nth-child(15) { animation-delay: .75s }
}

.my-shake {
    animation: shake .4s;
    animation-iteration-count: 1;
}

.my-shake-continue {
    animation: shake .4s infinite;
}
  
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
    }
    50% {
        transform: rotateX(-20deg);
    }
    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.bounce {
    animation: bounce .3s;
    // transform: scale(0);
}
  
@keyframes bounce {
    0% { transform: scale(0); opacity: 0 }
    40% { transform: scale(1.5); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
}

$delays: 20;
$delay-offset: .20s;
.delay-n {
    @for $i from 1 through $delays {
        &:nth-child(#{$i}) {
            transition-delay: ($i - 1) * $delay-offset !important;
        }
    }
}