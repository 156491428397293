
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($app-theme);

// User variables
$header-height: 65px;

$gradient: linear-gradient(90deg, rgba(255,1,237,1) 0%, rgba(255,2,85,1) 100%);

:root {
	--ion-color-primary: #fd0789;
	--ion-color-primary-rgb: 253,7,137;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #df0679;
	--ion-color-primary-tint: #fd2095;

	--ion-color-secondary: #63A1FF;
	--ion-color-secondary-rgb: 99,161,255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #578ee0;
	--ion-color-secondary-tint: #73aaff;
	
	--ion-color-tertiary: #A1D8D4;
	--ion-color-tertiary-rgb: 161,216,212;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #8ebebb;
	--ion-color-tertiary-tint: #aadcd8;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-dark: #090025;
	--ion-color-dark-rgb: 9,0,37;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #080021;
	--ion-color-dark-tint: #221a3b;

	--ion-color-medium: #7C8DB0;
	--ion-color-medium-rgb: 124,141,176;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #6d7c9b;
	--ion-color-medium-tint: #8998b8;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	// User defined
	--color-grey: #5d5d5e;
	--color-red: #ff0052;
	--color-pink: #fd0789;
	--color-pink-rgb: 253, 7, 137;
	--text-color: #282829;
	--text-color-2: #444;
	--text-color-3: #666;
	--text-color-off: #b1aaad;
	--text-color-accent: #282829;
	--background-color: #ffffff;
	--header-color: #282829;
	--header-color-rgb: 40, 40, 41;
	--accent-color: #ffffff;
	--box-shadow: #e2e8ef;
	--pink-accent: #fff5fa;
	--grey-border: #cbcbcb;
	--accent-color-two: #f5f5f5;
	--chat-bubble: #d9d9d9;

	// Josh
	--icon-btn-bg : #fff;
	--icon-btn-bg2 : #fff;
	--light-border: rgb(0,0,0,.2);
	--chip: #F2F1F3;
	--background-accent: rgb(0,0,0,.05);
	--background-accent-fill: #F2F2F2;
	--box-shadow-dark: rgb(0,0,0,.2);
}

/** Dark Colors **/
body.dark {
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

	// User defined
	--color-grey: #5d5d5e;
	--color-red: #ff0052;
	// --color-pink: #d93296;
	--color-pink: #fd0789;
	--color-pink-rgb: 253, 7, 137;
	--text-color: #ffffff;
	--text-color-2: #ccc;
	--text-color-3: #aaa;
	--text-color-off: #ffffff;
	--text-color-accent: #939393;
	--background-color: #282829;
	--header-color: #282829;
	--header-color-rgb: 40, 40, 41;
	--accent-color: #19191a;
	--box-shadow: rgb(0,0,0,.2);
	--pink-accent: #2e292d;
	--grey-border: #4b4b4c;
	--accent-color-two: #242425;
	--chat-bubble: #19191a;

	// Josh
	--icon-btn-bg : rgba(var(--ion-color-dark-rgb), .075);
	--icon-btn-bg2 : #3A3A3A;
	--light-border: rgb(255,255,255, .15);
	--chip: #333334;
	--background-accent: rgb(255,255,255,.05);
	--background-accent-fill: #333334;
	--box-shadow-dark: rgb(0,0,0,.4);
}

$facebook : #4267B2;
$twitter : #1C9CEA;
$youtube : #F70000;
$instagram : #EE752E;
$linkedin : #0073AF;
$medium : #FFC017;
$discord : #485EF4;
$twitch : #8C44F7;
$link : #808080;